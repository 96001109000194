import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import * as styles from "./tools.module.css";
import { Heading, MarkdownText, Section } from "gatsby-theme-landing-page";

export default function Tools({ heading, secondaryHeading, content }) {
  return (
    <Section>
      <Heading center>{heading}</Heading>
      <Heading secondary center>
        {secondaryHeading}
      </Heading>
      <div className={styles.content}>
        {content.map((item, i) => (
          <Tool {...item} />
        ))}
      </div>
    </Section>
  );
}

function Tool({ primaryText, secondaryText, tool_image_key, links }) {
  const image = getImage(tool_image_key.localImage);

  return (
    <div className={`${styles.featureContainer}`}>
      <div className={styles.copyColumn}>
        <div className={styles.copyContainer}>
          <MarkdownText
            as="h4"
            className={styles.primaryText}
            {...primaryText}
          />
          <MarkdownText className={styles.secondaryText} {...secondaryText} />
        </div>
      </div>
      <div className={`${styles.logoColumn}`}>
        <GatsbyImage
          backgroundColor="transparent"
          className={`${styles.logoImg}`}
          image={image}
          alt={image.title || primaryText}
        />
      </div>
    </div>
  );
}
