import * as React from "react";
import * as styles from "./hero.module.css";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Section from "../components/section";
import Button from "../components/button";
import MarkdownText from "../components/markdown-text";
import Heading from "../components/heading";

export default function PageHeading({ heading, secondaryHeading, content }) {
  const heroContent = content?.[0];
  const image = getImage(heroContent?.image_key.localImage);

  console.log("Content: ", content);
  console.log("Image: ", image);

  return (
    <Section style={{ "padding-top": 0, "padding-bottom": 0 }}>
      <div className={styles.root}>
        <div className={styles.pageHeading}>
          <Heading as="h1" className={styles.pageHeading}>
            <div>Take command</div>
            <div style={{ float: "right", "font-size": "var(--font-size-6)" }}>
              of your AI
            </div>
          </Heading>
        </div>

        <div className={styles.image}>
          <GatsbyImage image={image} alt={image.title || `Hero Image`} />
        </div>
      </div>
    </Section>
  );
}
