import * as React from "react";
import { graphql } from "gatsby";
import * as Components from "../sections";
import Layout from "../components/layout";
import Page from "../components/page";
import DevDebug from "../components/dev-debug";

export default function LandingPage(props) {
  const { sections } = props.data.page;

  return (
    <Layout {...props.data.page}>
      <Page>
        {sections.map((section) => {
          const Component = Components[section.component] || DevDebug;
          return Component ? <Component key={section.id} {...section} /> : null;
        })}
      </Page>
    </Layout>
  );
}

export const query = graphql`
  query ($id: String!) {
    page: contentfulLandingPage(id: { eq: $id }) {
      id
      logo_image_key {
        localImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, height: 100, placeholder: NONE)
          }
        }
      }

      sections {
        component
        heading
        secondaryHeading
        content {
          avatar_key {
            localImage {
              childImageSharp {
                gatsbyImageData(
                  layout: FIXED
                  width: 48
                  height: 48
                  placeholder: NONE
                )
              }
            }
          }
          image_key {
            localImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
              }
            }
          }

          tool_image_key {
            localImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
              }
            }
          }

          big_image_key {
            localImage {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  height: 500
                  placeholder: NONE
                )
              }
            }
          }

          medium_image_key {
            localImage {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  height: 300
                  placeholder: NONE
                )
              }
            }
          }

          primaryText {
            childMarkdownRemark {
              html
            }
          }
          secondaryText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    assets: allFile(filter: { dir: { regex: "/.*/assets/" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
      }
    }
  }
`;
