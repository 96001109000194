import * as React from "react";
import { Link } from "gatsby";
import { Head } from "gatsby-theme-landing-page";
import "../styles.css";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as cssVars from "gatsby-theme-landing-page/src/styles/variables.module.css";
import * as styles from "./layout.module.css";

export default function Layout(props) {
  const image = getImage(props.logo_image_key.localImage);
  return (
    <div className={[cssVars.root, styles.root].join(" ")}>
      <Head {...{ title: "Gistware", ...props }} />
      <header className={styles.header}>
        <div style={{ width: "100%", display: "flex" }}>
          <div style={{ width: "50%", float: "left" }}>
            <Link to="/">
              <GatsbyImage image={image} />
            </Link>
          </div>
          <div
            style={{
              width: "50%",
              float: "right",
              "text-align": "right",
              "align-content": "end",
            }}
          >
            <a
              href="mailto:hello@gistware.net"
              style={{ "font-size": "var(--font-size-2);" }}
            >
              hello@gistware.net
            </a>
          </div>
        </div>
      </header>
      <main className={styles.main}>{props.children}</main>
    </div>
  );
}
