// extracted by mini-css-extract-plugin
export var content = "tools-module--content--283ae";
export var contentContainer = "tools-module--contentContainer--b63de";
export var copyColumn = "tools-module--copyColumn--df018";
export var copyContainer = "tools-module--copyContainer--4a766";
export var featureContainer = "tools-module--featureContainer--e1f6f";
export var imageColumn = "tools-module--imageColumn--3ed8f";
export var logoColumn = "tools-module--logoColumn--88d94";
export var logoImg = "tools-module--logoImg--1cd5f";
export var primaryText = "tools-module--primaryText--ca256";
export var reverse = "tools-module--reverse--aa799";
export var secondaryText = "tools-module--secondaryText--fb2e8";